import React from "react";
import clsx from "clsx";
import { m } from "framer-motion";
import getAnimation from "@utils/getAnimation";

const PeopleTopPattern = ({ fitHeight, className: _className }) => {
  const draw = getAnimation("draw");
  return (
    <span
      className={clsx("icon block", _className, {
        "icon--fit-height": fitHeight,
      })}
    >
      <m.svg
        xmlns="http://www.w3.org/2000/svg"
        width="551"
        height="384"
        viewBox="0 0 551 384"
        initial="hidden"
        whileInView="visible"
        className="overflow-visible stroke-2 opacity-40 sm:stroke-1 sm:opacity-100"
        viewport={{
          once: true,
        }}
      >
        <m.path
          d="M270.798 40.9484C270.853 35.7411 275.09 31.5488 280.297 31.5488H443.324C449.759 31.5488 454.994 36.7292 455.062 43.1638C455.539 88.2833 419.095 125.115 373.973 125.115H293.798C280.645 125.115 270.021 114.379 270.16 101.226L270.798 40.9484Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M203.603 62.5508C210.139 62.5508 215.438 67.8494 215.438 74.3855V148.167C215.438 153.414 211.184 157.667 205.938 157.667H133.908C127.26 157.667 121.871 152.278 121.871 145.63V144.282C121.871 99.1432 158.464 62.5508 203.603 62.5508Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M299.314 62.5508C304.474 62.5508 308.656 66.7335 308.656 71.8932V115.156C308.656 163.464 269.495 202.625 221.188 202.625C217.82 202.625 215.09 199.895 215.09 196.527V72.0508C215.09 66.8041 219.343 62.5508 224.59 62.5508L299.314 62.5508Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M184.43 3.4799C184.43 1.85572 183.113 0.539063 181.489 0.539063L131.824 0.539063C83.5166 0.539063 44.3556 39.7 44.3556 88.0076C44.3556 91.3752 47.0856 94.1052 50.4532 94.1052L174.93 94.1052C180.176 94.1052 184.43 89.8519 184.43 84.6052L184.43 3.4799Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M95.6172 295.606C95.6172 343.913 56.4562 383.074 8.1487 383.074H6.70658C3.27921 383.074 0.500786 380.296 0.500786 376.868L0.500786 299.008C0.500786 293.761 4.75408 289.508 10.0008 289.508L89.5196 289.508C92.8872 289.508 95.6172 292.238 95.6172 295.606Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M146.59 163.606C146.59 211.913 107.429 251.074 59.1214 251.074H57.6792C54.2519 251.074 51.4734 248.296 51.4734 244.868V167.008C51.4734 161.761 55.7267 157.508 60.9734 157.508H140.492C143.86 157.508 146.59 160.238 146.59 163.606Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M550.5 88.0095C550.5 39.702 511.339 0.541016 463.032 0.541016L461.589 0.541016C458.162 0.541016 455.384 3.31945 455.384 6.74681V84.6071C455.384 89.8539 459.637 94.1071 464.884 94.1071L544.402 94.1071C547.77 94.1071 550.5 91.3771 550.5 88.0095Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
        <m.path
          d="M86.5664 64.1016C91.8131 64.1016 96.0664 68.3549 96.0664 73.6016L96.0664 281.343C96.0664 285.815 92.4413 289.44 87.9695 289.44C40.7661 289.44 2.50027 251.174 2.50027 203.971L2.50027 73.6015C2.50027 68.3548 6.75356 64.1016 12.0003 64.1016H86.5664Z"
          stroke="currentColor"
          fill="transparent"
          variants={draw}
        />
      </m.svg>
    </span>
  );
};

PeopleTopPattern.defaultProps = {};

export default PeopleTopPattern;
