import React from "react";
import clsx from "clsx";
import { Container, Image, Text, Button, ButtonAlt } from "@atoms";
import HeroPatternAlt from "@svg/HeroPatternAlt";
import HeroPatternCondensed from "@svg/HeroPatternCondensed";
import HeroTopPattern from "@svg/HeroTopPattern";

const HeroPattern = ({ separate, color }) => {
  const classes = clsx("flex h-full w-full", {
    "text-teal": color === "teal",
    "text-purple": color === "purple",
    "text-pink": color === "pink",
    "text-white": color === "white",
  });
  return (
    <div className="pointer-events-none absolute inset-0 z-20">
      <div
        className={clsx(
          "absolute flex h-auto items-end opacity-0 md:opacity-100",
          {
            "-bottom-20 -right-32 w-[26rem] sm:-right-28 sm:w-[30rem] md:-bottom-28 md:w-[32rem]":
              separate,
            "-bottom-10 -right-16 w-[18rem] sm:-bottom-6 sm:-right-10 sm:w-[22rem] md:-right-0 md:bottom-0 md:w-[26rem]":
              !separate,
          }
        )}
      >
        {!separate && <HeroPatternAlt className={classes} />}
        {separate && <HeroPatternCondensed className={classes} />}
      </div>
    </div>
  );
};

const PageHero = ({
  heading,
  descriptor,
  label,
  button,
  link,
  image,
  color,
  patternColor,
  separate,
  small,
  className: _className,
  dateAndTime,
  dateLinks,
}) => {
  const autoColorPick =
    color === "purple" || color === "transparent" ? "teal" : "white";
  return (
    <section
      className={clsx("mx-auto flex max-w-7xl justify-center px-4", _className)}
    >
      <div
        className={clsx("relative w-full rounded-t-3xl rounded-bl-3xl", {
          "rounded-br-3xl py-6 md:py-14": separate,
          "rounded-br-5xl py-12 sm:py-28 md:rounded-br-6xl": !separate,
          "bg-teal": color === "teal" && separate,
          "bg-purple": color === "purple" && separate,
          "bg-yellow": color === "yellow" && separate,
          "bg-slate": color === "slate" && separate,
          "bg-transparent md:pt-24": color === "transparent" && separate,
          "overflow-hidden": color !== "transparent",
        })}
      >
        {!separate && image && (
          <div className="absolute inset-0">
            <div className="absolute inset-0 z-10 bg-slate from-black via-black opacity-90 sm:bg-gradient-to-r sm:opacity-50 sm:mix-blend-multiply" />
            <Image eager image={image} fill />
          </div>
        )}
        <Container className="relative z-30">
          <div className="flex flex-wrap items-center flex-gap-4 sm:flex-gap-8 md:flex-nowrap">
            <div className="relative z-30 order-2 w-full md:order-1 md:w-1/2">
              <div className="flex max-w-sm flex-col space-y-4">
                {!!label && (
                  <Text
                    variant="label"
                    className={clsx({
                      "text-white": color === "purple" || !separate,
                      "text-slate":
                        (color === "teal" || color === "transparent") &&
                        separate,
                      "text-purple": color === "yellow" && separate,
                    })}
                  >
                    {label}
                  </Text>
                )}
                <Text
                  variant={small ? "h3" : "h1"}
                  className={clsx({
                    "text-white": color === "purple" || !separate,
                    "text-slate": color === "teal" && separate,
                    "text-purple":
                      (color === "yellow" || color === "transparent") &&
                      separate,
                    italic: heading === "Perspectives",
                    "text-teal": color === "slate" && separate,
                  })}
                >
                  {heading}
                </Text>
                {dateAndTime && (
                  <Text
                    variant="body"
                    className={clsx("font-bold text-yellow")}
                  >
                    {dateAndTime}
                  </Text>
                )}
                {dateLinks && (
                  <ul className="flex flex-wrap gap-5">
                    {Object.entries(dateLinks).map(([k, v]) => (
                      <li key={k}>
                        <Button size="xxs" color="yellow" to={v}>
                          + {k}
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
                <Text
                  variant="body"
                  richText
                  className={clsx("font-bold", {
                    "text-white":
                      color === "purple" ||
                      !separate ||
                      (color === "slate" && separate),
                    "text-slate": color !== "purple" && separate,
                  })}
                >
                  {descriptor}
                </Text>
              </div>
              {(button?.url || link?.url) && (
                <div className="mt-10 flex gap-x-4">
                  {button?.url && (
                    <Button
                      to={button.url}
                      size="sm"
                      color={
                        color === "purple" && separate ? "white" : "purple"
                      }
                    >
                      {button.text}
                    </Button>
                  )}
                  {link?.url && (
                    <ButtonAlt
                      color="white"
                      to={link.url}
                      className="text-white"
                    >
                      {link.text}
                    </ButtonAlt>
                  )}
                </div>
              )}
            </div>
            {separate && image && (
              <div className="relative z-10 order-1 w-full md:order-2 md:w-1/2 lg:-my-4">
                <div className="h-full">
                  <div className="relative h-48 w-full overflow-hidden rounded-t-3xl rounded-bl-3xl rounded-br-6xl xxs:h-32 sm:h-64 md:h-80">
                    <Image eager image={image} fill />
                  </div>
                </div>
              </div>
            )}
            {separate && (
              <HeroPattern
                separate={separate}
                color={patternColor || autoColorPick}
              />
            )}
          </div>
        </Container>
        {separate && color === "transparent" && (
          <HeroTopPattern className="absolute -left-4 -top-2 z-0 w-80 text-teal md:-left-8 md:w-[28rem]" />
        )}
        {!separate && (
          <HeroPattern separate={false} color={patternColor || autoColorPick} />
        )}
      </div>
    </section>
  );
};

PageHero.defaultProps = {
  color: "purple",
  separate: true,
};

export default PageHero;
